




























































import { Vue, Component, Watch } from 'vue-property-decorator';
import {EmpresaService}  from '@/core/services/geral';
import { AutenticadorService } from '@/core/services/AutenticadorService';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';

@Component
export default class Login extends Vue {
    logo: string = '';
    hidePassword: boolean = true;
    loading: boolean = false;
    showPassword: boolean = false;
    showPassword2: boolean = false;
    showPassword3: boolean = false;
    acesso: {login: string, senha: string} = {
      login: '',
      senha: ''
    };
    fieldRules: any[] = [
      (v: any) => !!v || 'Campo obrigatório',
    ]
    service = new AutenticadorService();

    redefinirSenha: boolean = false;
    resetarSenha: boolean = false;
    loadingResetarSenha: boolean = false;
    loginResetarSenha: string = '';
    tokenResetarSenha: string = '';
    novaSenhaResetarSenha: string = '';
    repetirSenha: string = '';
    validSenha: boolean = true;

    @Watch('tokenResetarSenha')
    WatchToken(){
      if(this.tokenResetarSenha != undefined){
        this.resetarSenha = true;
        this.redefinirSenha = true;
      }
    }

    Autenticar(){
      this.loading = true;

      this.service.AutenticarUsuario(this.acesso)
        .then(res => {
          AlertSimpleRes("Aviso!", res);          
          localStorage.setItem('sessionApp', JSON.stringify(res.data));
          this.$router.push({ name: 'home' });
        }, 
        err => {
          localStorage.clear();
          AlertSimpleErr("Aviso!", err)
        }).finally(() => this.loading = false);

    }

    RecuperarSenha(){
        
      this.loadingResetarSenha = true;
      this.service.ResetarUsuario(this.loginResetarSenha, this.tokenResetarSenha, this.novaSenhaResetarSenha).then(
        res=>{
          AlertSimpleRes("Aviso!", res);
          this.$router.replace({name:'login'});
          setTimeout(() => {
            location.reload(true);
          }, 2500);          
        },
        err=>{
          AlertSimpleErr("Aviso!", err);
        }
      ).finally(() => this.loadingResetarSenha = false);
      
    }

    KeyboardListener(e: any){
      if(e.key == "Enter" && !this.resetarSenha){        
        this.Autenticar();
      }
      else if(e.key == "Enter" && this.resetarSenha){
        this.RecuperarSenha();
      }
    }

    mounted() {
      
      window.addEventListener("keypress", this.KeyboardListener);

      const empresaService = new EmpresaService();
      empresaService.ObterLogo().then(
        res => {
          this.logo = res.data;
        }
      );

      this.tokenResetarSenha = this.$route.params.token;
    }

    beforeDestroy() {
      window.removeEventListener("keypress", this.KeyboardListener);
    }

}
