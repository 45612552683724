import {http, httpHeader} from '@/core/ApiConfig';
   
export class AutenticadorService {

    private _nomeControle = 'autenticador';

    public async AutenticarUsuario(usuario: any){
        return await http.post(`${this._nomeControle}/usuario`, usuario);
    }

    public async ResetarUsuario(usuarioLogin: string, tokenResetarSenha: string, novaSenhaResetarSenha: string){
        return await http.post(`${this._nomeControle}/resetarSenha`, null, {
            params:{
                usuarioLogin: usuarioLogin,
                token: tokenResetarSenha,
                novaSenha: novaSenhaResetarSenha
            },
            headers: httpHeader.headers
        });
    }
}